import React from "react";
import arrowSvg from "../images/down-arrow.svg";
import PropTypes from "prop-types";

import image from "../images/Noor-Ul-Baseer's_Picture.jpg";

const imageAltText = "Noor Ul Baseer's Picture";

const Home = ({ name, title }) => {
  const titleParts = title.split(' and '); // Split the title into two parts
  const firstPart = titleParts[0]; // "Software Engineer"
  const secondPart = titleParts[1]; // "UI/UX Designer"

  return (
    <section id="home" className="min-height" style={{ backgroundColor: "#2e3736" }}>
      {/*move the image to the left */}
      <div style={{ position: "absolute", top: "12rem", left: "35rem", width: "100rem", zIndex: "2" }}>
        <img
          className="background"
          src={image}
          alt={imageAltText}
          style={{
            width: "30%", // Set the width of the image to 30% of its parent container
            height: "auto", // Ensure the aspect ratio of the image is maintained
            position: "absolute", // Set the image position to absolute
            right: "5%", // Move the image 5% from the right side of its parent container
            top: "15%", // Move the image 5% from the top side of its parent container
            bottom: "5%", // Move the image 5% from the bottom side of its parent container
            zIndex: "1", // Set the z-index of the image to 1
            borderRadius: "50%", // Set the border radius of the image to 50% to make it circular
          }}
        />
      </div>
      <div style={{ color: "#008073", position: "absolute", top: "16rem", left: "15rem", width: "50rem", zIndex: "2" }}>
        <h1>{name}</h1>
      </div>
      <div style={{ color: "#008073", position: "absolute", top: "22rem", left: "15rem", width: "20rem", zIndex: "2" }}>
        <h2>{firstPart}</h2>
      </div>
      <div style={{ color: "#008073", position: "absolute", top: "25rem", left: "15rem", width: "20rem", zIndex: "2" }}>
        <h2>and</h2>
      </div>
      <div style={{ color: "#008073", position: "absolute", top: "28rem", left: "15rem", width: "20rem", zIndex: "2" }}>
        <h2>{secondPart}</h2>
      </div>
      <div style={{ position: "absolute", bottom: "3rem", left: "50%", zIndex: "2", transform: "translateX(-50%)" }}>
        <img src={arrowSvg} style={{ height: "3rem", width: "3rem", filter: "invert(0)" }} alt="Down arrow" />
      </div>
    </section>
  );
};

Home.defaultProps = {
  name: "",
  title: "",
};

Home.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Home;
