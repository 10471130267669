import React from "react";
import image from "../images/design-desk.jpeg";

const imageAltText = "desktop with books and laptop";

const projectList = [
  {
    title: "Static Website Development (Grocery Store) 🎉",
    description:
      "Designed and developed a static website showcasing a grocery store using HTML and CSS.",
    url: "https://github.com/NoorUlBaseer/FASTGROCERS-Static-Website",
  },
  {
    title: "Ecommerce Mobile Application Development 🎉",
    description:
      "Developed an e-commerce mobile application for Android using Dart and the Flutter framework and implemented it in Android Studio.",
    url: "https://github.com/NoorUlBaseer/SamaanGhar.pk-E-commerce-Mobile-Application",
  },
  {
    title: "UI Designs for Sports Equipment Management System 🎉",
    description:
      "Designed the user interface for a mobile app to streamline the sports equipment management process at the university.",
  },
  {
    title: "Race Car Game 🎉",
    description:
      "Developed a 2D console-based racing game in C++ to apply data structures knowledge.",
    url: "https://github.com/NoorUlBaseer/2D-Race-Car-Game-Data-Structures-Project",
  },
  {
    title: "Teaching Assistant (TA) and Lab Demonstrator (LD) Management System 🎉",
    description:
      "Developed a management system in C# using WinForms to streamline TA and LD tasks. Utilized MS SQL Server and SQL Server Management Studio to design and maintain the database.",
    url: "https://github.com/NoorUlBaseer/Teaching-Assistant-and-Lab-Demonstrator-Management-System",
  },
  {
    title: "Financial Management System Website 🎉",
    description:
      "Developed a website using ReactJS for the frontend, Spring Boot for the backend, and MySQL Workbench for the database.",
    url: "https://github.com/NoorUlBaseer/Finance-Management-System",
  },
];

const Portfolio = () => {
  return (
    <section className="padding" id="portfolio">
      <h2 style={{ textAlign: "center" }}>Portfolio</h2>
      <div style={{ display: "flex", flexDirection: "row", paddingTop: "3rem" }}>
        <div style={{ maxWidth: "40%", alignSelf: "center" }}>
          <img
            src={image}
            style={{ height: "90%", width: "100%", objectFit: "cover" }}
            alt={imageAltText}
          />
        </div>
        <div className="container">
          {projectList.map((project) => (
            <a
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
              className="box"
              key={project.title}
              style={{ display: "block", textDecoration: "none", color: "inherit" }}
            >
              <h3 style={{ flexBasis: "40px" }}>{project.title}</h3>
              <p className="small">{project.description}</p>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
